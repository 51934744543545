<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="12">

                <CButton @click="$router.go(-1)" size="sm" color="warning">
                    <font-awesome-icon icon="arrow-left" /> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12">
                <CAlert show color="primary">
                    <h4 class="alert-heading"><font-awesome-icon icon="share-from-square" /> {{ $route.query.name }}
                    </h4>
                    <hr>
                    <p class="mb-0">
                        Has {{ [...new
                            Set(document_template_shared.filter(item => item.deleted_at == null)
                                .map((item) => item.name))]
                            .length }} shared document template/s.
                    </p>
                </CAlert>
            </CCol>
        </CRow>

        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader>
                        <font-awesome-icon icon="share-from-square" /> Shared Document Template/s
                    </CCardHeader>
                    <CCardBody>
                        <CTabs :active-tab="active_tab" @update:activeTab="changeTab">
                            <CTab>
                                <template slot="title">
                                    Active Document Template/s Shared
                                    <CBadge color="success">( {{ [...new
                                        Set(document_template_shared.filter(item => item.deleted_at === null)
                                        .map((item) => item.name))]
                                        .length }} )
                                    </CBadge>
                                    <a-popover title="NOTE:" placement="right" style="padding-left: 4px;">
                                        <template slot="content">
                                            <template>
                                                <i>This indicates that the document template/s shared to this prompt are
                                                    active.</i> <br>
                                            </template>
                                        </template>
                                        <font-awesome-icon style="color: #468dc8;" icon="question-circle" />
                                    </a-popover>
                                </template>

                                <CRow>
                                    <CCol lg="12">
                                        <div style="display: flex;" class="search-container">
                                            <span style="font-size: 14px; padding-top: 5px; padding-right: 5px;">Search:
                                            </span>
                                            <input type="text" id="search" autocomplete="off"
                                                :class="$store.getters.getDarkMode ? 'dark-theme search-box' : 'search-box'"
                                                placeholder="Enter text here." v-model="searchTerm">
                                        </div>
                                        <CDataTable :items="filteredItems" :fields="templates_field" class="active-templates-table"
                                            :items-per-page="10" border @row-clicked="dtRowClicked" sorter pagination
                                            :loading="this.table_loading" hover>
                                            <template slot="no-items-view" v-if="this.table_loading">
                                                <center>
                                                    <CSpinner style="width:4rem;height:4rem;" color="info" />
                                                </center>
                                            </template>

                                            <template #checkbox-header>
                                                <center>
                                                    <p-check class="p-icon p-bigger" color="success"
                                                        v-model="isCheckedAll" @change="selectChange($event)">
                                                        <font-awesome-icon class="icon" icon="check" />
                                                    </p-check>
                                                </center>
                                            </template>

                                            <template #checkbox="{ item }">
                                                <td class="checkbox-column">
                                                    <center>
                                                        <p-check class="p-icon p-bigger" color="success" :value="item"
                                                            v-model="selected_templates">
                                                            <font-awesome-icon class="icon" icon="check" />
                                                        </p-check>
                                                        &nbsp;
                                                    </center>
                                                </td>
                                            </template>

                                        </CDataTable>
                                    </CCol>
                                </CRow>
                                <CRow v-if="!table_loading">
                                    <CCol lg="3">
                                        <CButton block size="sm" color="danger" @click=" removePrompt()"
                                            v-if="$store.getters['can']('replace-document-template-shared')"
                                            :disabled="action_control">
                                            <font-awesome-icon icon="times" /> Remove Prompt
                                        </CButton>
                                    </CCol>
                                    <CCol lg="3">
                                        <CButton block size="sm" color="warning"
                                            @click="prompt_modal = true, getPrompts()"
                                            v-if="$store.getters['can']('remove-document-template-shared')"
                                            :disabled="action_control">
                                            <font-awesome-icon icon="balance-scale" /> Replace Prompt
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab title="Inactive">
                                <template slot="title">
                                    Inactive Document Template/s
                                    <CBadge color="danger">( {{ [...new
                                        Set(document_template_shared.filter(item => item.deleted_at !== null)
                                        .map((item) => item.name))]
                                        .length }} )
                                    </CBadge>
                                    <a-popover title="NOTE:" placement="right" style="padding-left: 4px;">
                                        <template slot="content">
                                            <template>
                                                <i>This indicates that the document template/s shared to this prompt are
                                                    currently not active.</i> <br>
                                            </template>
                                        </template>
                                        <font-awesome-icon style="color: #468dc8;" icon="question-circle" />
                                    </a-popover>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <div style="display: flex;" class="search-container">
                                            <span style="font-size: 14px; padding-top: 5px; padding-right: 5px;">Search:
                                            </span>
                                            <input type="text" id="search" autocomplete="off"
                                                :class="$store.getters.getDarkMode ? 'dark-theme search-box' : 'search-box'"
                                                placeholder="Enter text here." v-model="searchTermInactive">
                                        </div>
                                        <CDataTable :items="filteredItemsInactive" :fields="templates_field" class="inactive-templates-table"
                                            :items-per-page="10" border @row-clicked="rowClickedInactive" sorter hover
                                            pagination :loading="this.table_loading">
                                            <template slot="no-items-view" v-if="this.table_loading" hover>
                                                <center>
                                                    <CSpinner style="width:4rem;height:4rem;" color="info" />
                                                </center>
                                            </template>

                                            <template #checkbox-header>
                                                <center>
                                                    <p-check class="p-icon p-bigger" color="success"
                                                        v-model="isCheckedAllInactive"
                                                        @change="selectChangeInactive($event)">
                                                        <font-awesome-icon class="icon" icon="check" />
                                                    </p-check>
                                                </center>
                                            </template>

                                            <template #checkbox="{ item }">
                                                <td class="inactive-checkbox-column">
                                                    <center>
                                                        <p-check class="p-icon p-bigger" color="success" :value="item"
                                                            v-model="selected_templates">
                                                            <font-awesome-icon class="icon" icon="check" />
                                                        </p-check>
                                                        &nbsp;
                                                    </center>
                                                </td>
                                            </template>

                                        </CDataTable>
                                    </CCol>
                                </CRow>
                                <CRow v-if="!table_loading">
                                    <CCol lg="3">
                                        <CButton block size="sm" color="danger" @click=" removePrompt()"
                                            v-if="$store.getters['can']('remove-document-template-shared')"
                                            :disabled="action_control">
                                            <font-awesome-icon icon="times" /> Remove User
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>


                </CCard>
            </CCol>
        </CRow>

        <CRow>
            <CCol lg="12">
                <template>
                    <CCollapse :show="collapse">
                        <div>
                            <CCard>
                                <CCardHeader @click="innerCollapse = !innerCollapse">
                                    <label style="padding-top: 5px;"> <font-awesome-icon icon="share-from-square" />
                                        Selected Document Template/s</label>
                                </CCardHeader>
                                <CCollapse :show="innerCollapse" class="mt-2">
                                    <CCardBody>

                                        <CDataTable :items="selected_templates.length > 0 ? selected_templates : []"
                                            :fields="collapse_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }"
                                            :items-per-page="10" items-per-page-select border sorter pagination
                                            :loading="table_loading" size="sm" hover>
                                            <template slot="no-items-view" v-if="this.table_loading">
                                                <center>
                                                    <CSpinner style="width:4rem;height:4rem;" color="info" />
                                                </center>
                                            </template>

                                        </CDataTable>
                                    </CCardBody>
                                </CCollapse>
                            </CCard>
                        </div>
                    </CCollapse>
                </template>
            </CCol>
        </CRow>
        <CModal :show.sync="prompt_modal" color="primary" size="lg" :closeOnBackdrop="false">
            <template #header>
                <h5> <font-awesome-icon icon="user" /> Prompt list </h5>
                <CButton class="pull-right" color="light" shape="pill" size="sm" @click="prompt_modal = false">
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CDataTable :items="sorted_prompts" :fields="prompt_fields" class="prompts-table"
                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }" :items-per-page="10"
                        items-per-page-select border sorter pagination @row-clicked="selectedPrompt"
                        :loading="table_loading" hover>
                    </CDataTable>
                </CCardBody>
            </template>
            <template #footer>
                <hr hide>
            </template>
        </CModal>
    </div>

</template>
<style>
.prompts-table tbody tr, .active-templates-table tbody tr, .inactive-templates-table tbody tr{
  cursor: pointer;
}

.active-templates-table tbody tr td.checkbox-column, .inactive-templates-table tbody tr td.inactive-checkbox-column{
  cursor: default
}

</style>

<script>
export default {
    name: "DocumentTemplateSharedEdit",
    data() {
        return {
            replace_document_shared_modal: false,
            remove_document_shared_modal: false,
            remove_shared_documents: false,
            selectAllChecked: false,
            isCheckedAll: false,
            isCheckedAllInactive: false,
            document_template_shared: [],
            user_id: null,
            table_loading: true,
            selected_templates: [],
            filtered_data: [],
            filtered_data_inactive: [],
            document_template_shared_inactive: [],
            collapse: false,
            searchTerm: '',
            searchTermInactive: '',
            innerCollapse: true,
            collapse_fields: [
                {
                    key: 'name'
                },
                {
                    key: 'category_name'
                },
            ],
            active_tab: 0,
            prompt_id: null,
            prompt_modal: false,
            prompts: [],
            selected_prompt: {},
            prompts_table_loading: false,
            decoded_prompt_id: null,
        }

    },
    created() {
        this.user_id = this.$route.params.id;
        this.prompt_id = this.$route.query.prompt_id;
        this.decoded_prompt_id = this.paramDecoder(this.$route.query.prompt_id);

        this.getDocumentTemplateShared();
    },
    computed: {
        templates_field() {
            let custom_fields_with_permission = [
                {
                    key: "checkbox", label: '', _classes: 'th-fixed-width'
                },
                { key: 'name', label: 'Template Name' },
                { key: 'category_name', label: 'Category Name' },
            ];
            let custom_fields = [
                { key: 'name', label: 'Template Name' },
                { key: 'category_name', label: 'Category Name' },
                { key: 'shared_by', label: 'Shared By' },
            ];
            if (this.$store.getters['can']('remove-document-template-shared') || this.$store.getters['can']('replace-document-template-shared')) {
                return custom_fields_with_permission;
            } else {
                return custom_fields;
            }
        },

        action_control: function () {
            return this.selected_templates.length > 0 ? false : true;
        },
        filteredItemsInactive() {
            let filteredDataInactive = this.document_template_shared
                .filter(item => item.deleted_at !== null)
                .filter(item =>
                    Object.values(item).some(value =>
                        value != null && value.toString().toLowerCase().includes(this.searchTermInactive.toLowerCase())
                    )
                ).sort((a, b) => a.name.localeCompare(b.name));

            this.filtered_data_inactive = filteredDataInactive;
            return filteredDataInactive;
        },

        filteredItems() {
            let filteredData = this.document_template_shared
                .filter(item => item.deleted_at === null)
                .filter(item =>
                    Object.values(item).some(value =>
                        value != null && value.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
                    )
                ).sort((a, b) => a.name.localeCompare(b.name));

            this.filtered_data = filteredData;
            return filteredData;
        },
        prompt_fields() {
            let custom_fields = [
                { key: 'name', label: 'Name' },
                { key: 'description', label: 'Description' },
            ];
            return custom_fields;
        },
        sorted_prompts() {
            return this.prompts
                .filter(item => item.id != this.decoded_prompt_id)
                .sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    methods: {
        changeTab: function (val) {
            this.selected_templates = [];
            this.active_tab = val;
        },
        getDocumentTemplateShared: function () {
            this.$Progress.start()
            let requestData = {
                prompt_id: this.prompt_id
            };
            axios.post('/drs/template-prompt-shared/dt-list', requestData, { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {
                        this.document_template_shared = response.data.data;
                        this.table_loading = false;
                        this.$Progress.finish()
                    }

                });
        },

        dtRowClicked: function (item, index, column, e) {
            if (this.$store.getters['can']('replace-document-template-shared') || this.$store.getters['can']('remove-document-template-shared')) {
                if (column != 'checkbox') {
                    let i = this.selected_templates.findIndex((templates) => templates.id === item.id);
                    if (i !== -1) {
                        this.selected_templates.splice(i, 1);
                        if (this.selected_templates.length < 1) {
                            this.isCheckedAll = false;
                            this.resetCollapse();
                        }
                    } else {
                        this.selected_templates.push(item);
                        this.collapse = true;
                    }
                }
            }
        },


        rowClickedInactive: function (item, index, column, e) {
            if (this.$store.getters['can']('remove-document-template-shared')) {
                if (column != 'checkbox') {
                    let i = this.selected_templates.findIndex((templates) => templates.id === item.id);
                    if (i !== -1) {
                        this.selected_templates.splice(i, 1);
                        if (this.selected_templates.length < 1) {
                            this.isCheckedAllInactive = false;
                            this.resetCollapse();
                        }
                    } else {
                        this.selected_templates.push(item);
                        this.collapse = true;
                    }
                }
            }
        },

        selectChange() {

            if (!this.isCheckedAll) {
                this.selected_templates = [];
                this.collapse = false;
            } else {
                if (this.searchTerm != '') {
                    if (this.selected_templates.length > 0) {
                        for (let i = 0; i < this.filtered_data.length; i++) {
                            this.selected_templates.push(this.filtered_data[i]);
                        }

                    } else {
                        this.selected_templates = this.filtered_data.map(item => item);
                    }
                } else {
                    this.selected_templates = this.document_template_shared.map(item => item);
                }
                if (this.document_template_shared.length > 0) {
                    this.collapse = true;
                }
            }
        },
        selectChangeInactive() {
            
            if (!this.isCheckedAllInactive) {
                this.selected_templates = [];
                this.collapse = false;
                console.log("checked 1");

            } else {
                if (this.searchTermInactive != '') {
                    if (this.selected_templates.length > 0) {
                        for (let i = 0; i < this.filtered_data_inactive.length; i++) {
                            this.selected_templates.push(this.filtered_data_inactive[i]);
                        }

                    } else {
                        this.selected_templates = this.filtered_data_inactive.map(item => item);
                        console.log("checked 2");

                    }
                } else {
                    this.selected_templates = this.document_template_shared.map(item => item);
                    console.log("checked 3");
                }
                if (this.document_template_shared_inactive.length > 0) {
                    this.collapse = true;
                    console.log("checked 4");

                }
            }
        },
        removePrompt: function () {
            let promptName = this.$route.query.name;
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: this.active_tab == 0 ? `You are trying to <b>remove</b> ${promptName} from the selected document template(s).` : `The selected document template is currently inactive, would you still like to <b>remove</b> ${promptName} from the document template(s)`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    this.$Progress.start()
                    let _data = {
                        document_template_id: [],
                    };

                    if (this.selected_templates.length > 0) {
                        for (let i = 0; i < this.selected_templates.length; i++) {
                            let dt_id = this.paramEncoder(this.selected_templates[i].id);
                            _data.document_template_id.push(dt_id);
                        }
                    }
                    return axios.post('/drs/template-prompt-shared/remove', _data, { validateStatus: () => true })
                        .then(response => {
                            if (response.status == 200) {
                                this.$swal({
                                    toast: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    icon: 'success',
                                    title: `Prompt(s) removed successfully!`,
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                })
                                this.isCheckedAll = false;
                                this.isCheckedAllInactive = false;
                                this.selected_templates = [];
                                this.filtered_data = [],
                                    this.filtered_data_inactive = [];
                                this.document_template_shared = [];
                                this.document_template_shared_inactive = [];
                                this.resetCollapse();
                                this.getDocumentTemplateShared();
                                this.table_loading = false;
                                this.$Progress.finish()
                            }
                        });

                }
            }).then((result) => {
                if (!result.value) {
                    this.$swal('Cancelled!', '', 'error');
                }
            })
            return;
        },

        resetCollapse: function () {
            this.collapse = false;
            /* this.innerCollapse = false; */
        },

        getPrompts: function () {

            this.prompts_table_loading = true;
            this.$Progress.start()
            axios.get('drs/template-prompt-shared/list', { validateStatus: () => true })
                .then(response => {
                    this.prompts = response.data.data;
                    this.prompts_table_loading = false;
                    this.$Progress.finish()
                });
        },
        rowClicked: function (item, index, column, e) {
            console.log("clicked");

            if (column != 'checkbox') {
                let i = this.selected_templates.findIndex((templates) => templates.id === item.id);
                if (i !== -1) {
                    if (column != 'checkbox') {

                        this.selected_templates.splice(i, 1);
                    } else {
                        if (this.selected_templates.length == 1) {
                            this.isCheckedAll = false;
                            this.selected_templates = [];

                        }
                    }
                    if (this.selected_templates.length < 1) {
                        this.isCheckedAll = false;

                    }
                } else {
                    this.selected_templates.push(item);
                    console.log(this.selected_templates);
                    this.collapse = true;
                }
            }
        },
        selectedPrompt: function (item, index, column, event) {
            this.selected_prompt.name = item.name;
            this.selected_prompt.id = item.id;
            this.promptSelected();
        },
        promptSelected: function () {
            let _data = {
                document_template_id: [],
                prompt_id: this.selected_prompt.id
            };

            if (this.selected_templates.length > 0) {
                for (let i = 0; i < this.selected_templates.length; i++) {
                    let dt_id = this.paramEncoder(this.selected_templates[i].id);
                    _data.document_template_id.push(dt_id);
                }
            }
            let promptName = this.$route.query.name;
            this.$swal({
                icon: "warning",
                title: "Confirm Update",
                html: `The prompt in the selected document template(s) will be <b>updated</b> from the ${promptName} to the ${this.selected_prompt.name}.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Update Prompt",
                cancelButtonText: 'Cancel',
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    this.$Progress.start()
                    return axios.post('/drs/template-prompt-shared/replace', _data, { validateStatus: () => true })
                        .then(response => {
                            if (response.status == 200) {
                                this.$swal({
                                    toast: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    icon: 'success',
                                    title: `Prompt(s) replace successfully!`,
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                })
                                this.prompt_modal = false
                                this.isCheckedAll = false;
                                this.isCheckedAllInactive = false;
                                this.selected_templates = [];
                                this.filtered_data = [],
                                    this.filtered_data_inactive = [];
                                this.document_template_shared = [];
                                this.document_template_shared_inactive = [];
                                this.resetCollapse();
                                this.getDocumentTemplateShared();
                                this.table_loading = false;
                                this.$Progress.finish()
                            }
                        });

                }
            }).then((result) => {
                if (!result.value) {
                    this.$swal('Cancelled!', '', 'error');
                }
            })
        },

    },
    watch: {
        selected_templates: function (value) {
            if (value) {
                this.collapse = true;
                if (this.selected_templates.length < 1) {
                    this.isCheckedAll = false;
                    this.isCheckedAllInactive = false;
                    this.collapse = false;
                }
            }
        },
    },

}
</script>